import FloatBar from '@/components/FloatBar/FloatBar.vue';
import FloatAds from '@/components/FloatAds/FloatAds.vue'


export default {
    data() {
        return {
            ieltsTab: [{
                    url: '/toefl/index',
                    name: '首页',
                },
                {
                    url: '/toefl/community',
                    name: '公益课',
                },
                {
                    url: '/toefl/activity',
                    name: '训练营',
                },
                {
                    url: '/toefl/shop',
                    name: '课程商城',
                },
                {
                    url: '/toefl/material',
                    name: '资料中心',
                },
                {
                    url: '/toefl/article',
                    name: '精品文章',
                },
            ],
        };
    },
    components: {
        FloatBar,
        FloatAds
    },
    metaInfo: {
        title: '趴趴教育唯一官方网站 - 雅思,托福,GMAT走心的在线英语培训平台',
        meta: [{
            name: 'keywords',
            content: '趴趴雅思,趴趴托福,趴趴GMAT,趴趴雅思小助手,雅思,托福,GMAT,PTE,实用英语',
        }, ],
    },
};
