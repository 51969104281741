<template>
  <div class="ielts-page">
    <div class="ielts-tab-box">
      <div class="ielts-tab">
        <div class="box"></div>
        <div class="ielts-tab-item" v-for="item in ieltsTab" :key="item.url">
          <router-link :to="item.url">
            <div class="" :class="`icon-papa-${item.icon}`">{{item.name}}</div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="ielts-content">
      <router-view name="toefl"></router-view>
    </div>
    <FloatBar category="toefl"></FloatBar>
    <FloatAds></FloatAds>

  </div>
</template>

<script>
  import ToeflJs from './Toefl.js'

  export default ToeflJs
</script>

<style lang="less">
  .ielts-page {
    & > .ielts-tab-box {
      width: 100%;
      border-bottom: 1px solid #F2F2F2;
      background: #fff;

      & > .ielts-tab {
        height: 40px;
        line-height: 40px;
        width: 1200px;
        margin: 0 auto;
        display: flex;

        & > .box {
          margin-right: 170px;
        }

        & > .ielts-tab-item {
          margin-right: 2em;

          & > a {
            color: #555;
            font-size: 14px;
          }

          & > .router-link-active {
            color: #29d087;
          }
        }
      }
    }
  }
</style>
